import { useEffect } from 'react'
import { matchPath, useLocation, useNavigate } from 'react-router'
import { useSessionStorageState } from 'ahooks'
import { Layout, Menu } from 'antd'

import { IMenu, useMenus } from './useMenus'
import styles from './styles.module.less'

/** 左侧菜单栏 */
export const LeftSider = () => {
  const navigate = useNavigate()
  const { pathname } = useLocation()
  const [collapsed, setCollapsed] = useSessionStorageState('oms_bizLeftSiderCollapsed', {
    defaultValue: false,
  })
  const { menus, items } = useMenus(!!collapsed)

  const defaultOpenKeys = menus.filter(menu => menu.children).map(({ key }) => key)

  const selectedKeys: string[] = []
  const loop = (menu: IMenu): void => {
    if (menu.children) {
      return menu.children.forEach(loop)
    }
    if (menu.key.startsWith('/')) {
      if (matchPath(menu.pathPattern || menu.key, pathname)) {
        selectedKeys.push(menu.key)
      }
    }
  }
  menus.forEach(loop)

  useEffect(() => {
    let id = requestAnimationFrame(() => {
      id = 0
      document
        .querySelector(`.${styles.box} .ant-menu-item-selected`)
        ?.scrollIntoView({ behavior: 'smooth', block: 'nearest' })
    })
    return () => void (id && cancelAnimationFrame(id))
  }, [pathname])

  return (
    <Layout className={styles.box}>
      <Layout.Sider
        className={styles.sider}
        theme="light"
        collapsible
        collapsedWidth={50}
        collapsed={collapsed}
        onCollapse={setCollapsed}
      >
        <div className="demo-logo-vertical" />
        <Menu
          className={styles.menu}
          mode="inline"
          defaultOpenKeys={collapsed ? undefined : defaultOpenKeys}
          selectedKeys={selectedKeys}
          onClick={({ key }): void => {
            if (key.startsWith('/')) return navigate(key)
            if (/^https?:\/\/.+/.test(key)) return void window.open(key, '_blank')
          }}
          items={items}
        />
      </Layout.Sider>
    </Layout>
  )
}

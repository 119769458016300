/** 查找最近的滚动容器，查至 body 或 html 时返回 null。涉及 dom 访问，请慎用！ */
export const findClosestScrollContainer = (element: Element | null): Element | null => {
  if (typeof document === 'undefined') return null // site ssr
  if (!(element instanceof Element)) return null // Element 保护

  const find = (ele: Element): Element | null => {
    if (ele === document.body || ele === document.documentElement) return null

    const val = getComputedStyle(ele).overflow
    if (val.includes('auto') || val.includes('scroll') || val.includes('overlay')) return ele

    if (ele.parentElement) {
      return find(ele.parentElement)
    }

    return null
  }

  return find(element)
}

import { memo } from 'react'
import { useMatches, useNavigate } from 'react-router'
import { Breadcrumb } from 'antd'
import type { ItemType } from 'antd/es/breadcrumb/Breadcrumb'
import { HomeOutlined } from '@ant-design/icons'

/** 面包屑导航 */
export const BreadcrumbNav = memo(function BreadcrumbNav() {
  const navigate = useNavigate()
  const matches = useMatches()

  const items = matches.map<ItemType>(({ id: key, pathname: href, handle }, index) => {
    const title: ItemType['title'] = Object(handle).name?.() || ''

    const onClick: ItemType['onClick'] = e => {
      e.preventDefault()
      navigate(href)
    }

    const item: ItemType = { key, title, href, onClick }

    // 第一个
    if (index === 0) {
      return { ...item, title: <HomeOutlined /> }
    }
    // 最后一个
    if (index === matches.length - 1) {
      return { key, title }
    }
    // 中间
    return item
  })

  return <Breadcrumb items={items} />
})

import { ReactNode } from 'react'
import { theme } from 'antd'

import { PageHeader } from '../PageHeader'
import styles from './styles.module.less'

/** 页面布局 */
export const PageLayout = ({
  minWidth,
  leftSider,
  rightContent,
}: {
  minWidth?: number
  leftSider?: ReactNode
  rightContent: ReactNode
}) => {
  const { token } = theme.useToken()
  const borderValue = `1px solid ${token.colorSplit}`

  return (
    <div className={styles.scrollBox}>
      <div className={styles.box} style={{ minWidth: minWidth }}>
        <div style={{ borderBottom: borderValue }} className={styles.header}>
          <PageHeader />
        </div>
        <div className={styles.main}>
          {!!leftSider && (
            <div style={{ borderRight: borderValue }} className={styles.main_left}>
              {leftSider}
            </div>
          )}
          <div className={styles.main_right}>{rightContent}</div>
        </div>
      </div>
    </div>
  )
}
